import { Tabs, Title } from '@mantine/core';
import useRouterQuery from '@/hooks/useRouterQuery';
import { useState } from 'react';
import ConjointAnalysisComponent from './conjointAnalysis';
import InsomniaExperiment from './insomniaExperiment';
import { InsomniaResults } from './InsomniaResults';

export const InsomniaDashboardComponent = () => {
    const { handleURLParamsChange, router } = useRouterQuery();
    const [tab, setTab] = useState(router?.query.tab || 'warmstart');

    return (
        <div className="h-full justify-center p-10 !pt-6 lg:!px-24">
            <Title order={2} className="mb-4 basis-1/12 text-navy-solid-50">
                Insomnia Cookies, Dashboard
            </Title>

            <div className="mt-8 flex flex-col gap-10">
                <div className="flex flex-col">
                    <Tabs defaultValue={tab as string}>
                        <Tabs.List>
                            <Tabs.Tab
                                value="warmstart"
                                onClick={() => {
                                    setTab('warmstart');
                                    handleURLParamsChange({ key: 'tab', value: 'warmstart' });
                                }}
                            >
                                Warm Start
                            </Tabs.Tab>
                            <Tabs.Tab
                                value="experiment"
                                onClick={() => {
                                    setTab('experiment');
                                    handleURLParamsChange({ key: 'tab', value: 'experiment' });
                                }}
                            >
                                Personalized Pricing Design
                            </Tabs.Tab>
                            <Tabs.Tab
                                value="results"
                                onClick={() => {
                                    setTab('results');
                                    handleURLParamsChange({ key: 'tab', value: 'results' });
                                }}
                            >
                                Results
                            </Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="warmstart">
                            <ConjointAnalysisComponent />
                        </Tabs.Panel>
                        <Tabs.Panel value="experiment">
                            <InsomniaExperiment />
                        </Tabs.Panel>
                        <Tabs.Panel value="results">
                            <InsomniaResults />
                        </Tabs.Panel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
