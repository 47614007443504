import { DropdownOption } from 'andromeda';
import _ from 'lodash';

export type DatasetVolume = 'all' | 'low' | 'high';
export const DATASET_VOLUMES: DatasetVolume[] = ['all', 'high', 'low'];

export const DATASET_VOLUME_OPTIONS: DropdownOption<DatasetVolume>[] = DATASET_VOLUMES.map(v => ({
    label: _.startCase(v),
    value: v,
}));

export const TREATMENT_TYPE_OPTIONS = [
    {
        value: 'original_treatment',
        label: '14 original treatment stores',
    },
    {
        value: 'added_treatment',
        label: '36 scale-up treatment stores',
    },
    {
        value: 'all',
        label: 'All 50 treatment stores',
    },
];

export type ChartType =
    | 'daily_average_revenue'
    | 'cumulative_daily_average_revenue_difference'
    | 'cumulative_daily_average_revenue_lift';

export const CHART_TYPES: ChartType[] = [
    'daily_average_revenue',
    'cumulative_daily_average_revenue_difference',
    'cumulative_daily_average_revenue_lift',
];

export const CHART_TYPE_OPTIONS: DropdownOption<ChartType>[] = CHART_TYPES.map(v => ({
    label: _.startCase(v),
    value: v,
}));

export const COMMON_VERTICAL_REFERENCE_LINES = {
    line1: {
        type: 'line',
        scaleID: 'x',
        value: '2024-03-15',
        borderColor: '#515674',
        label: {
            display: true,
            content: 'Experiment start date',
            position: 'start',
            xAdjust: -10,
            backgroundColor: 'transparent',
            color: '#515674',
            font: {
                weight: 'normal',
            },
            rotation: 270,
        },
    },
    line2: {
        type: 'line',
        scaleID: 'x',
        value: '2024-04-12',
        borderColor: '#515674',
        label: {
            display: true,
            content: 'Store expansion',
            position: 'start',
            xAdjust: -10,
            backgroundColor: 'transparent',
            color: '#515674',
            font: {
                weight: 'normal',
            },
            rotation: 270,
        },
    },
    model_v2_launch: {
        type: 'line',
        scaleID: 'x',
        value: '2024-05-23',
        borderColor: '#515674',
        label: {
            display: true,
            content: 'Model v2 launch',
            position: 'start',
            xAdjust: -15,
            backgroundColor: 'transparent',
            color: '#515674',
            font: {
                weight: 'normal',
            },
            rotation: 270,
        },
    },
} as const;

export const TREATMENT_TEXT_ANNOTATIONS = {
    line3: {
        type: 'line',
        scaleID: 'x',
        value: '2024-03-15',
        borderColor: 'transparent',
        label: {
            display: true,
            content: ['14 stores', 'in Treatment'],
            position: 'start',
            xAdjust: 90,
            yAdjust: -5,
            backgroundColor: 'transparent',
            color: '#515674',
            font: {
                weight: 'normal',
                size: 13,
            },
            rotation: 0, // Horizontal text
        },
    },
    line4: {
        type: 'line',
        scaleID: 'x',
        value: '2024-04-12',
        borderColor: 'transparent',
        label: {
            display: true,
            content: ['All 50 stores', 'in Treatment'],
            position: 'start',
            xAdjust: 50,
            yAdjust: -5,
            backgroundColor: 'transparent',
            color: '#515674',
            font: {
                weight: 'normal',
                size: 13,
            },
            rotation: 0, // Horizontal text
        },
    },
};

export const COLOR_PALETTE_FOR_HEATMAP = [
    '#012a4a',
    '#013a63',
    '#01497c',
    '#014f86',
    '#2a6f97',
    '#2c7da0',
    '#468faf',
    '#61a5c2',
    '#89c2d9',
    '#a9d6e5',
];
