/* eslint-disable react/no-array-index-key */
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import ColorBarComponent from './ColorBar';
import StyledTooltip from '../../components/utils/styledTooltip';

const DEFAULT_CELL_HEIGHT = 20;
const DEFAULT_CELL_WIDTH = 30;

const StyledHeatmap = styled.div<{ columns: number; cellWidth: number }>`
    display: grid;
    grid-template-columns: repeat(
        ${props => props.columns},
        minmax(${props => props.cellWidth}px, 1fr)
    );
`;

const Cell = styled.div<{ color: string; cellHeight: number }>`
    height: ${props => props.cellHeight}px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.color};
    color: #fff;
    text-align: center;
    font-size: 12px;
`;

const Label = styled.div`
    text-align: center;
    font-size: 12px;
`;

const YAxisCell = styled.div<{ cellHeight: number }>`
    height: ${props => props.cellHeight}px;
    font-size: 12px;
    display: flex;
    align-items: center;
`;

interface HeatmapProps {
    data: number[][];
    xLabels: string[];
    yLabels: string[];
    colorPalette: string[];
    cellWidth?: number;
    cellHeight?: number;
    yAxisLabel?: string;
    xAxisLabel?: string;
    colorBarClassName?: string;
    barHeight?: number;
    formatValue?: (value: number) => string;
    formatColorBarValue?: (value: number) => string;
    disableTooltip?: boolean;
}

export const Heatmap: FC<HeatmapProps> = ({
    data,
    xLabels,
    yLabels,
    colorPalette,
    xAxisLabel,
    yAxisLabel,
    colorBarClassName,
    barHeight,
    disableTooltip = true,
    formatValue,
    formatColorBarValue,
    cellWidth = DEFAULT_CELL_WIDTH,
    cellHeight = DEFAULT_CELL_HEIGHT,
}) => {
    const { minValue, maxValue } = useMemo(() => {
        const flatData = data.flat();
        return {
            minValue: Math.min(...flatData),
            maxValue: Math.max(...flatData),
        };
    }, [data]);

    const calculatedBarHeight = yLabels.length * cellHeight;

    const getValue = (disableTooltipValue: boolean, value: number) => {
        if (disableTooltipValue) {
            if (formatValue) {
                return formatValue(value);
            }
            return value.toFixed(2);
        }
        return null;
    };

    return (
        <div className="flex">
            <div className="flex gap-2">
                {yAxisLabel && (
                    <div className="flex h-full items-center justify-center">
                        <div className="w-5 -rotate-90 whitespace-nowrap text-sm">{yAxisLabel}</div>
                    </div>
                )}
                <div className="flex flex-col">
                    {yLabels.map((label, index) => (
                        <YAxisCell
                            key={index}
                            className="text-right text-navy-solid-70"
                            cellHeight={cellHeight}
                        >
                            {label}
                        </YAxisCell>
                    ))}
                </div>
                <div>
                    <StyledHeatmap columns={xLabels.length} cellWidth={cellWidth}>
                        {data.map((row, rowIndex) => (
                            <React.Fragment key={rowIndex}>
                                {row.map((value, cellIndex) => {
                                    const normalizedValue =
                                        (value - minValue) / (maxValue - minValue);
                                    const colorIndex = Math.floor(
                                        normalizedValue * (colorPalette.length - 1),
                                    );
                                    const val = getValue(disableTooltip, value);
                                    const tooltipLabel = getValue(!disableTooltip, value);
                                    return (
                                        <StyledTooltip
                                            key={`${rowIndex}-${cellIndex}`}
                                            label={tooltipLabel}
                                            disabled={disableTooltip}
                                        >
                                            <Cell
                                                color={colorPalette[colorIndex]}
                                                cellHeight={cellHeight}
                                            >
                                                {val}
                                            </Cell>
                                        </StyledTooltip>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </StyledHeatmap>
                    <div className="flex justify-center">
                        {xLabels.map((label, index) => (
                            <Label key={index} className="flex-1 py-1 text-navy-solid-70">
                                {label}
                            </Label>
                        ))}
                    </div>
                    {xAxisLabel && <div className="text-center text-sm">{xAxisLabel}</div>}
                </div>
            </div>
            <ColorBarComponent
                className={colorBarClassName}
                colorPalette={colorPalette}
                minValue={minValue}
                maxValue={maxValue}
                barHeight={barHeight ?? calculatedBarHeight}
                formatColorBarValue={formatColorBarValue}
            />
        </div>
    );
};
