import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { InsomniaDashboardComponent } from '@/components/insomnia/insomniaDashboardComponent';

const InsomniaDashboard = () => (
    <ResponsiveLayout title="Insomnia Dashboard" className="relative !p-0" hideNavBar>
        <InsomniaDashboardComponent />
    </ResponsiveLayout>
);

export default InsomniaDashboard;
