import _ from 'lodash';
import { useHeatmapData } from './useHeatmapData';

export const useHeatmapComponentData = () => {
    const { data: heatmapData, isLoading: heatmapDataIsLoading } = useHeatmapData();
    const xLabels = _.keys(heatmapData?.context_groups[_.keys(heatmapData.context_groups)[0]]);
    const yLabels = _.keys(heatmapData?.context_groups);

    const heatmapDataForComponent = yLabels.map(key => _.values(heatmapData?.context_groups[key]));

    return {
        xLabels,
        yLabels,
        heatmapData: heatmapDataForComponent,
        heatmapDataIsLoading,
    };
};
