import moment from 'moment';
import { ChartType, COMMON_VERTICAL_REFERENCE_LINES, TREATMENT_TEXT_ANNOTATIONS } from './types';

export const getChartTitle = (chartType: ChartType) => {
    if (chartType === 'daily_average_revenue') {
        return 'Daily Gross Revenue Averages';
    }

    if (chartType === 'cumulative_daily_average_revenue_difference') {
        return 'Cumulative Average Revenue per Store';
    }

    if (chartType === 'cumulative_daily_average_revenue_lift') {
        return 'Cumulative Average Revenue per Store';
    }

    return '';
};

export const getCommonVerticalReferenceLines = (dateRange: [string, string]) => {
    const lines = {};
    if (moment(COMMON_VERTICAL_REFERENCE_LINES.line1.value).isBetween(dateRange[0], dateRange[1])) {
        lines['line1'] = COMMON_VERTICAL_REFERENCE_LINES.line1;
    }

    if (moment(COMMON_VERTICAL_REFERENCE_LINES.line2.value).isBetween(dateRange[0], dateRange[1])) {
        lines['line2'] = COMMON_VERTICAL_REFERENCE_LINES.line2;
    }

    if (
        moment(COMMON_VERTICAL_REFERENCE_LINES.model_v2_launch.value).isBetween(
            dateRange[0],
            dateRange[1],
        )
    ) {
        lines['model_v2_launch'] = COMMON_VERTICAL_REFERENCE_LINES.model_v2_launch;
    }
    return lines;
};

export const getAnnotations = (treatmentType: string, dateRange: [string, string]) => {
    const annotations = { ...getCommonVerticalReferenceLines(dateRange) };
    if (treatmentType === 'all') {
        if (moment(TREATMENT_TEXT_ANNOTATIONS.line3.value).isBetween(dateRange[0], dateRange[1])) {
            annotations['line3'] = TREATMENT_TEXT_ANNOTATIONS.line3;
        }

        if (moment(TREATMENT_TEXT_ANNOTATIONS.line4.value).isBetween(dateRange[0], dateRange[1])) {
            annotations['line4'] = TREATMENT_TEXT_ANNOTATIONS.line4;
        }

        return annotations;
    }

    return annotations;
};

export const getCumulativeDailyAverageRevenueAnnotations = (
    treatmentType: string,
    dateRange: [string, string],
) => {
    const baselineContent = (function () {
        if (treatmentType === 'added_treatment') {
            return ['10-day', 'baseline', 'before', 'store', 'expansion'];
        }
        return ['10-day', 'baseline', 'before', 'experiment', 'start'];
    })();
    const baselineTextAnnotation = {
        type: 'line',
        scaleID: 'x',
        value: '2024-03-15',
        borderColor: 'transparent',
        label: {
            display: true,
            content: baselineContent,
            position: 'start',
            xAdjust: treatmentType === 'added_treatment' ? 120 : -55,
            yAdjust: 10,
            backgroundColor: 'transparent',
            color: '#515674',
            font: {
                weight: 'normal',
                size: 13,
            },
            rotation: 0,
        },
    };

    const annotations = getAnnotations(treatmentType, dateRange);

    if (moment(baselineTextAnnotation.value).isBetween(dateRange[0], dateRange[1])) {
        annotations['baselineText'] = baselineTextAnnotation;
    }

    return annotations;
};
