import styled from 'styled-components';

const CheckContainer = styled.svg`
    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }
    @keyframes scale {
        0%,
        100% {
            transform: none;
        }
        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }
    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 30px #345dee;
        }
    }
`;

export const AnimatedCheck = () => (
    <CheckContainer
        className="block size-10 animate-[fill_0.4s_ease-in-out_0.4s_forwards,scale_0.3s_ease-in-out_0.9s_both] rounded-[50%] stroke-white stroke-2 shadow-[inset_0px_0px_0px_#345dee]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
    >
        <circle
            className="animate-[stroke_0.6s_cubic-bezier(0.65,0,0.45,1)_forwards] fill-none stroke-[#345dee] stroke-2"
            cx="26"
            cy="26"
            r="25"
            fill="none"
        />
        <path
            className="origin-[50%_50%] animate-[stroke_0.3s_cubic-bezier(0.65,0,0.45,1)_0.8s_forwards]"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
    </CheckContainer>
);
