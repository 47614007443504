/* eslint-disable consistent-return */
import { Info, Package, RocketLaunch, Sparkle } from 'phosphor-react';
import { Input, NumberInput, RangeSlider, Slider } from '@mantine/core';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'andromeda/Button';
import { grayScale, primaryColors } from '@/constants/colors';
import { BareCard } from 'andromeda/Card';
import Text from 'andromeda/text';
import { Spinner } from 'andromeda';
import { DMSModal } from '../experiment/DMSModal';
import useCreateInsomniaExperiment from './hooks/useCreateInsomniaExperiment';
import { AnimatedCheck } from './check';
import useGetExperiement from './hooks/useGetExperiment';

const InsomniaExperiment = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessScreen, setIsSuccessScreen] = useState(false);
    const [error, setError] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [selectedNamespace, setSelectedNamespace] = useState<string>('US');
    const [experimentTitle, setExperimentTitle] = useState<string>('USA - Maximize revenue 2024');
    const [sliders, setSliders] = useState<[number, number][]>([
        [15, 30],
        [15, 30],
    ]);

    const createExperimentMtx = useCreateInsomniaExperiment();
    const { data: pastExperiment, isLoading } = useGetExperiement();

    const createExperiment = async () => {
        setIsSubmitting(true);

        try {
            const r = await createExperimentMtx.mutateAsync({
                name: experimentTitle,
                type: 'AB',
                allocation_method: 'stratified',
                split_column: 'gross_revenue',
                split_time_period: 'hour',
                groups: [
                    {
                        group_name: 'string',
                        allocation_percentage: 0.5,
                        min_value: sliders[0][0],
                        max_value: sliders[0][1],
                        num_values: 5,
                        is_control: false,
                    },
                    {
                        group_name: 'string',
                        allocation_percentage: 0.5,
                        min_value: 23,
                        max_value: 23,
                        num_values: 23,
                        is_control: true,
                    },
                ],
            });

            setIsSuccessScreen(true);
            setIsSubmitting(false);
        } catch (_error) {
            setError('Failed to create experiment. Please try again.');
        }
    };

    useEffect(() => {
        if (isSuccessScreen) {
            const timer = setTimeout(() => {
                setIsSuccessScreen(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [isSuccessScreen]);

    const EXPERIMENT_TYPES = [
        {
            title: 'Dynamic Model Selection',
            subtitle: 'Reinforcement Learning to apply optimal recommendations',
            icon: <Package size={26} />,
            disabled: true,
        },
        {
            title: 'Test Setup',
            subtitle: 'Traditional A/B testing to apply optimal recommendations',
            icon: <Sparkle size={20} />,
        },
    ];

    const MARKS = [
        { value: 15, label: '15%' },
        { value: 30, label: '30%' },
    ];
    return (
        <BareCard className="!mt-4 !rounded !bg-white !p-8">
            {isSubmitting && <Spinner />}

            <div className="flex w-full grow flex-col">
                {isSuccessScreen ? (
                    <div className="flex flex-col gap-2 pt-1">
                        <div className="flex flex-row justify-center gap-8 pb-8">
                            <AnimatedCheck />
                            <Text type="14Reg" className="!mt-2 !text-xl !text-primaryBlue">
                                Congratulations, you just launched a new experiment!
                            </Text>
                        </div>
                        <p>Experiment: {experimentTitle}</p>
                        <p>Region: {selectedNamespace}</p>
                        <p>Use Case: Increase Revenue</p>
                        {/* <Button
                            className="mt-10"
                            leftSection={<RocketLaunch size={20} />}
                            onClick={() => setIsSuccessScreen(false)}
                        >
                            Launch another
                        </Button> */}
                    </div>
                ) : (
                    <div className="flex flex-col gap-12">
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-3">
                                <div className="text-sm font-medium text-navy">
                                    1. Name your personalized pricing strategy
                                </div>
                            </div>

                            <div className="w-[606px]">
                                <Input
                                    value={experimentTitle}
                                    onChange={e => setExperimentTitle(e.target.value)}
                                    classNames={{ input: 'text-xs' }}
                                    placeholder="Enter a name for your experiment"
                                    className="gradient-bg-hex w-full rounded border border-border-color focus:border-primary-50"
                                />
                            </div>
                        </div>

                        <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-3">
                                <div className="flex items-center gap-10">
                                    <div className="text-sm font-medium text-navy">
                                        2. Select an experiment type
                                    </div>
                                    <button
                                        type="button"
                                        className="group flex cursor-pointer items-center gap-2"
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        <Info
                                            className="text-navy-solid-30 group-hover:text-navy-solid-70"
                                            size={20}
                                        />
                                        <p className="text-sm font-medium text-navy-solid-30 group-hover:text-navy-solid-70">
                                            What is Dynamic Model Selection?
                                        </p>
                                    </button>
                                </div>
                            </div>

                            <div className="flex items-center gap-4">
                                {(true ? EXPERIMENT_TYPES.reverse() : EXPERIMENT_TYPES).map(row => (
                                    <div
                                        key={row.title}
                                        className={classNames(
                                            'flex flex-row items-center w-60 h-full rounded-lg gap-2 p-2 border-t border-l border-border-color',
                                            {
                                                'bg-primary-3 cursor-pointer': !row.disabled,
                                                'opacity-30': row.disabled,
                                            },
                                        )}
                                    >
                                        {row.icon}
                                        <div className="flex flex-col">
                                            <div className="text-sm text-navy-solid-70">
                                                {row.title} {row.disabled && '(Coming Soon!)'}
                                            </div>
                                            <div className="text-xs text-navy-solid-50">
                                                {row.subtitle}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="mb-4 flex flex-col gap-4">
                            <div className="flex items-center gap-3">
                                <div className="text-sm font-medium text-navy">
                                    3. What percentage of total customers should the experiment
                                    apply?
                                </div>
                            </div>

                            <div className="flex w-[303px] flex-col gap-4">
                                <div className="flex-1">
                                    <Slider
                                        value={50}
                                        marks={[
                                            { value: 20, label: '20%' },
                                            { value: 50, label: '50%' },
                                            { value: 80, label: '80%' },
                                        ]}
                                        disabled
                                        styles={{
                                            track: {
                                                background: grayScale[10],
                                                color: grayScale[10],
                                                height: 4,
                                            },
                                            thumb: {
                                                height: '16px',
                                                width: '16px',
                                                borderColor: primaryColors.blue,
                                                background: primaryColors.blue,
                                                borderWidth: '2.5px',
                                                border: 0,
                                            },
                                            bar: {
                                                backgroundColor: primaryColors.blue,
                                            },
                                            mark: {
                                                borderColor: null,
                                                height: 4,
                                                width: 4,
                                                color: primaryColors.blue,
                                                backgroundColor: primaryColors.blue,
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-3">
                                <div className="text-sm font-medium text-navy">
                                    4. Identify inflation and the boundaries.
                                </div>
                            </div>

                            <div className="flex w-[740px] flex-row items-center gap-6">
                                <BareCard className="rounded">
                                    <div className="text-sm font-semibold text-navy">Treatment</div>
                                    <div className="ml-4 mt-1.5 flex min-h-[120px] flex-col items-center gap-6 text-sm font-medium text-navy">
                                        <div className="flex w-[303px] flex-col gap-4">
                                            <div className="text-xs font-medium text-navy">
                                                What is the inflation range that should be applied
                                                to treatment?
                                            </div>
                                            <div className="my-2 flex-1">
                                                <RangeSlider
                                                    minRange={1}
                                                    step={0.1}
                                                    value={sliders[0]}
                                                    onChange={v => setSliders([v, sliders[1]])}
                                                    color="blue"
                                                    marks={MARKS}
                                                    min={15}
                                                    max={30}
                                                    styles={{
                                                        track: {
                                                            background: grayScale[10],
                                                            color: grayScale[10],
                                                            height: 4,
                                                        },
                                                        thumb: {
                                                            height: '14px',
                                                            width: '14px',
                                                            borderColor: primaryColors.blue,
                                                            background: primaryColors.blue,
                                                            borderWidth: '2.5px',
                                                            border: 0,
                                                        },
                                                        bar: {
                                                            backgroundColor: primaryColors.blue,
                                                        },
                                                        mark: {
                                                            borderColor: null,
                                                            height: 4,
                                                            width: 4,
                                                            color: primaryColors.blue,
                                                            backgroundColor: primaryColors.blue,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </BareCard>
                                <BareCard className="rounded">
                                    <div className="text-sm font-semibold text-navy">Control</div>
                                    <div className="ml-4 mt-1.5 flex min-h-[120px] flex-col items-center gap-4 text-sm font-medium text-navy">
                                        <div className="flex h-full w-[303px] flex-col gap-4">
                                            <div className="text-xs font-medium text-navy">
                                                What is the inflation value that should be applied
                                                to control?
                                            </div>
                                            <div className="mb-3 mt-2 flex flex-1 justify-center align-middle">
                                                <NumberInput
                                                    min={0}
                                                    max={100}
                                                    defaultValue={23}
                                                    suffix="%"
                                                    className="w-24"
                                                    disabled
                                                    styles={{
                                                        label: {
                                                            fontSize: '12px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </BareCard>
                            </div>
                        </div>

                        <Button
                            leftSection={<RocketLaunch size={16} />}
                            onClick={createExperiment}
                            className="w-[180px] rounded-3xl  bg-risd-blue text-xs font-normal hover:bg-risd-blue hover:opacity-80 focus:focus-within:bg-risd-blue"
                            color="blue"
                            disabled={!!pastExperiment?.id}
                            disabledTooltipLabel="You already have an active experiment. Please wait for it to complete before launching another."
                        >
                            Launch Experiment
                        </Button>
                        {error && <p className="text-red-500">{error}</p>}
                    </div>
                )}
            </div>
            <DMSModal opened={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </BareCard>
    );
};

export default InsomniaExperiment;
