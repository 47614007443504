import { useEffect, useState } from 'react';
import { Slider } from '@mantine/core';
import { Button } from 'andromeda/Button';
import { colorScales, grayScale, primaryColors } from '@/constants/colors';
import Text from 'andromeda/text';
import { BareCard } from 'andromeda/Card';
import { Spinner } from 'andromeda/Spinners/spinner';
import { useAppContext } from '@/contexts/appContext';
import { Check, Checks } from 'phosphor-react';
import { notification } from '@/utils/notification';
import useGetWarmStartQuestions from './hooks/useGetWarmStartQuestions';
import SubmitWarmStartQuestions from './hooks/submitWarmStartQuestions';
import useGetWarmStartAnswers from './hooks/useGetWarmStartAnswers';
import { AnimatedCheck } from './check';
import useGetExperiement from './hooks/useGetExperiment';

const ConjointAnalysisComponent = () => {
    const { user } = useAppContext();

    const [inputFields, setInputFields] = useState([]);
    const [isSuccessScreen, setIsSuccessScreen] = useState(false);

    const { data: questions, isFetching: isFetchingQuestions } = useGetWarmStartQuestions();
    const { data: answers, isFetching: isFetchingAnswers } = useGetWarmStartAnswers();

    const { data: pastExperiment, isLoading } = useGetExperiement();

    useEffect(() => {
        if (questions?.questions) {
            const d = questions.questions.map(q => ({
                confidence: 0.5,
                question_id: q.question_id,
            }));
            setInputFields(d);
        }
    }, [questions]);

    const submitWS = async () => {
        const result = await SubmitWarmStartQuestions(inputFields, user);
        if (result.answers.length > 0) {
            notification({
                message: 'Answers submitted succesfully.',
                icon: <Checks size={32} color={colorScales.green[0]} />,
            });
        }
        setIsSuccessScreen(true);
        window.scrollTo(0, 0);
    };

    const nullifyConfidence = (index, id) => {
        const d = inputFields && [...inputFields];
        d[index] = { question_id: id };
        setInputFields(d);
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isSuccessScreen) {
            const timer = setTimeout(() => {
                setIsSuccessScreen(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [isSuccessScreen]);

    if (isSuccessScreen)
        return (
            <BareCard className="!mt-4 h-[100px] !rounded !bg-white !p-8">
                <div className="flex flex-row justify-center gap-8">
                    <AnimatedCheck />
                    <Text type="14Reg" className="!mt-2 !text-xl !text-primaryBlue">
                        Thank you for submitting your answers
                    </Text>
                </div>
            </BareCard>
        );

    return (
        <BareCard className="!mt-4 !rounded !bg-white !p-8">
            {isFetchingAnswers && <Spinner />}
            {!isFetchingAnswers && (
                <div className="flex flex-col gap-6">
                    {isFetchingQuestions && <Spinner />}
                    {questions?.questions.map((row, index) => (
                        <div className="mb-8 flex flex-col gap-6" key={row.question}>
                            <div className="text-sm text-navy">
                                {index + 1}. {row.question}
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <div className="flex flex-row gap-6 px-10">
                                    <Text type="12Reg" className="!text-navy-solid-70">
                                        Not at all
                                    </Text>
                                    <Slider
                                        value={inputFields[index]?.confidence}
                                        onChange={v => {
                                            const d = inputFields && [...inputFields];
                                            d[index]['confidence'] = v;
                                            d[index]['question_id'] = row.question_id;
                                            setInputFields(d);
                                        }}
                                        step={0.1}
                                        min={0}
                                        max={1}
                                        label={null}
                                        styles={{
                                            track: {
                                                background: grayScale[10],
                                                color: grayScale[10],
                                                height: 4,
                                                width: '200px',
                                            },
                                            thumb: {
                                                height: '14px',
                                                width: '14px',
                                                borderColor: primaryColors.blue,
                                                background: primaryColors.blue,
                                                borderWidth: '2.5px',
                                                border: 0,
                                            },
                                            bar: {
                                                backgroundColor: primaryColors.blue,
                                            },
                                        }}
                                    />
                                    <Text type="12Reg" className="!text-navy-solid-70">
                                        Very
                                    </Text>
                                </div>
                                <Button
                                    variant="secondary"
                                    className="!hover:text-navy-solid-30 group !h-7 !text-navy-solid-70"
                                    onClick={() => nullifyConfidence(index, row.question_id)}
                                >
                                    <Text type="12Reg" className="group-hover:text-white">
                                        I dont know
                                    </Text>
                                </Button>
                            </div>
                        </div>
                    ))}
                    <Button
                        variant="primary"
                        className="!self-end"
                        onClick={submitWS}
                        leftSection={<Check size={16} />}
                        disabled={!!pastExperiment?.id}
                        disabledTooltipLabel="You have an active experiment."
                    >
                        <Text type="14Reg" className="!text-white">
                            Submit Warm Start
                        </Text>
                    </Button>
                </div>
            )}
        </BareCard>
    );
};

export default ConjointAnalysisComponent;
