import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../../../contexts/appContext';
import { IRIS_URL } from '../../../buildConfig/processEnv';

type WarmStartQuestions = {
    questions: WarmStartQuestion[];
};

type WarmStartQuestion = {
    question_id: string;
    question: string;
    context_features: any;
};

const useGetWarmStartQuestions = () => {
    const { user } = useAppContext();
    const getRequest = async () => {
        try {
            const requestOptions: RequestInit = {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    authorization: `Bearer ${user.siberiaToken}`,
                },
            };

            const url = `${IRIS_URL}/spaceport/api/optimizer/namespace/US/get_warm_start_questions`;

            const response = await fetch(url, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const body = await response.json();
            return body;
        } catch (error) {
            throw new Error('An error occurred while making the post request');
        }
    };

    return useQuery({ queryKey: ['useGetWarmStartQuestions'], queryFn: getRequest });
};

export default useGetWarmStartQuestions;
