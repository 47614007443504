import { Modal } from '@mantine/core';
import { FC } from 'react';

export const DMSModal: FC<{
    opened: boolean;
    onClose: () => void;
}> = ({ opened, onClose }) => {
    const a = 'a';

    return (
        <Modal
            size="600px"
            classNames={{ title: 'text-lg font-bold' }}
            opened={opened}
            onClose={onClose}
            title="Dynamic Model Selection"
            className="rounded-lg bg-primary "
            centered
        >
            <div className="flex h-auto min-h-[175px] flex-col justify-between">
                <div className="flex flex-row justify-between border-t border-border-color px-4">
                    <div className="flex flex-row gap-2">
                        <div className="mt-4 text-base text-navy-solid-50">
                            Uses reinforcement learning (RL) to learn and apply optimal
                            recommendations from a set of candidate recommendations. The difference
                            between the treatment and control group captures the difference
                            attributable to this learning process relative to a baseline that
                            typically uses a static model.
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
