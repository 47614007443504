import React from 'react';
import _ from 'lodash';
import { BareCard, Spinner } from 'andromeda';
import { useTransactionTime } from './useTransactionTime';
import { Charts } from './Charts';
import { Heatmap } from '../../../andromeda/Heatmap/Heatmap';
import { useHeatmapComponentData } from './useHeatmapComponentData';
import { COLOR_PALETTE_FOR_HEATMAP } from './types';

export const InsomniaResults = () => {
    const { data } = useTransactionTime();
    const { xLabels, yLabels, heatmapData, heatmapDataIsLoading } = useHeatmapComponentData();

    return (
        <div className="mt-4 space-y-4">
            <div className="text-sm text-primary-70">
                Last transaction data received date from insomnia: {data}
            </div>
            <div className="mt-6 grid auto-cols-fr grid-cols-1 gap-4 lg:grid-cols-2">
                <Charts />
                <BareCard className="flex h-full flex-col items-center justify-center gap-2 rounded">
                    <div className="self-start pl-[22px] text-sm text-gray-500">
                        Average Revenue per Action (normalized) by Context Group
                    </div>
                    {heatmapDataIsLoading && <Spinner className="grow" />}
                    {!heatmapDataIsLoading && (
                        <Heatmap
                            data={heatmapData}
                            xLabels={xLabels}
                            yLabels={yLabels}
                            colorPalette={COLOR_PALETTE_FOR_HEATMAP}
                            cellWidth={60}
                            yAxisLabel="Context Group"
                            xAxisLabel="Price Markup (%)"
                            colorBarClassName="ml-8"
                        />
                    )}
                    <div className="self-start text-xs text-navy-solid-50">
                        *The context group is ordered by total revenue generated since the start of
                        the experiment.
                    </div>
                </BareCard>
            </div>
        </div>
    );
};
