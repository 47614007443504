import useApiQuery from '@/hooks/useApiQuery';
import { IRIS_URL } from 'buildConfig/processEnv';
import { DatasetVolume } from './types';

export interface DailyGrossRevenueResponse {
    store_context: string;
    treatment_type: string;
    data: DailyGrossRevenueData;
}

export type DailyGrossRevenueDataType = {
    control: Record<
        string,
        {
            avg_daily_gross_revenue: number;
            lower_bound: number;
            upper_bound: number;
        }
    >;
    treatment: Record<
        string,
        {
            avg_daily_gross_revenue: number;
            lower_bound: number;
            upper_bound: number;
        }
    >;
};

export type CumulativeDiffTimestampDataType = Record<
    string,
    {
        diff: number;
        lower_bound: number;
        upper_bound: number;
    }
>;

export type CumulativeLiftTimestampDataType = Record<
    string,
    {
        lift: number;
        lower_bound: number;
        upper_bound: number;
    }
>;

export type CumulativeLift = {
    baseline: {
        mean: number;
        lower_bound: number;
        upper_bound: number;
    };
    timestamp_data: CumulativeLiftTimestampDataType;
};

export type CumulativeDiff = {
    baseline: {
        mean: number;
        lower_bound: number;
        upper_bound: number;
    };
    timestamp_data: CumulativeDiffTimestampDataType;
};

export interface DailyGrossRevenueData {
    cumulative_diff: CumulativeDiff;
    cumulative_lift: CumulativeLift;
    daily: DailyGrossRevenueDataType;
}

interface UseDailyGrossRevenueParams {
    volume: DatasetVolume;
    treatmentType: string;
    startDate: string;
    endDate: string;
}

export const useDailyGrossRevenue = ({
    volume,
    treatmentType,
    startDate,
    endDate,
}: UseDailyGrossRevenueParams) => {
    const { data, ...res } = useApiQuery<DailyGrossRevenueResponse>({
        url: `${IRIS_URL}/spaceport/api/optimizer/namespace/US/get_time_series_graph`,
        method: 'POST',
        body: {
            store_context: volume,
            treatment_type: treatmentType,
            start_date: startDate,
            end_date: endDate,
        },
    });

    return { data: data?.data, ...res };
};
