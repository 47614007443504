import { IRIS_URL } from 'buildConfig/processEnv';

export type WarmStartAnswers = {
    question_id: string;
    confidence: number;
};

const SubmitWarmStartQuestions = async (answerFields: WarmStartAnswers[], user) => {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `Bearer ${user.siberiaToken}`,
        },
        body: JSON.stringify({ answers: answerFields }),
    };

    const url = `${IRIS_URL}/spaceport/api/optimizer/namespace/US/post_warm_start_answer`;

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        return new Error('Network response was not ok');
    }

    const body = await response.json();
    return body;
};

export default SubmitWarmStartQuestions;
