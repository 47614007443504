import React, { FC, useMemo } from 'react';
import _ from 'lodash';
import colors, { primaryColors } from '@/constants/colors';
import LineChart from '@/components/utils/charts/lineChart';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart, registerables } from 'chart.js';
import Color from 'color';
import { ChartType } from './types';
import { getAnnotations, getCumulativeDailyAverageRevenueAnnotations } from './utils';
import { CumulativeDiff, CumulativeLift } from './useDailyGrossRevenue';

Chart.register(...registerables, annotationPlugin);

interface CumulativeDailyAverageRevenueProps {
    chartType: ChartType;
    cumulativeDailyAverageRevenueDiffData: CumulativeDiff;
    cumulativeDailyAverageRevenueLiftData: CumulativeLift;
    treatmentType: string;
    dateRange: [string, string];
}
export const CumulativeDailyAverageRevenue: FC<CumulativeDailyAverageRevenueProps> = ({
    chartType,
    cumulativeDailyAverageRevenueDiffData,
    cumulativeDailyAverageRevenueLiftData,
    treatmentType,
    dateRange,
}) => {
    const isLift = chartType === 'cumulative_daily_average_revenue_lift';

    const chartData = useMemo(() => {
        if (isLift) {
            return {
                datasets: [
                    {
                        label: 'Lift',
                        data: _.map(
                            cumulativeDailyAverageRevenueLiftData.timestamp_data,
                            (val, key) => ({
                                x: key,
                                y: (val.lift * 100).toFixed(2),
                            }),
                        ),
                        backgroundColor: primaryColors.purple,
                        borderColor: primaryColors.purple,
                        maxBarThickness: 30,
                        yAxisID: 'y',
                    },
                    {
                        label: 'Lift Upper Bound',
                        data: _.map(
                            cumulativeDailyAverageRevenueLiftData.timestamp_data,
                            (val, key) => ({
                                x: key,
                                y: (val.upper_bound * 100).toFixed(2),
                            }),
                        ),
                        backgroundColor: Color(primaryColors.purple).alpha(0.2).string(),
                        fill: '+1', // fills the area between this line and the next dataset
                        borderColor: 'transparent',
                    },
                    {
                        label: 'Lift Lower Bound',
                        data: _.map(
                            cumulativeDailyAverageRevenueLiftData.timestamp_data,
                            (val, key) => ({
                                x: key,
                                y: (val.lower_bound * 100).toFixed(2),
                            }),
                        ),
                        backgroundColor: Color(primaryColors.purple).alpha(0.2).string(),
                        fill: '-1', // fills the area between this line and the previous dataset
                        borderColor: 'transparent',
                    },
                    {
                        label: 'Baseline Upper Bound',
                        data: _.map(
                            cumulativeDailyAverageRevenueLiftData.timestamp_data,
                            (val, key) => ({
                                x: key,
                                y: (
                                    cumulativeDailyAverageRevenueLiftData.baseline.upper_bound * 100
                                ).toFixed(2),
                            }),
                        ),
                        backgroundColor: Color(colors.yellow).alpha(0.2).string(),
                        fill: '+1', // fills the area between this line and the previous dataset
                        borderColor: 'transparent',
                    },
                    {
                        label: 'Baseline Lower Bound',
                        data: _.map(
                            cumulativeDailyAverageRevenueLiftData.timestamp_data,
                            (val, key) => ({
                                x: key,
                                y: (
                                    cumulativeDailyAverageRevenueLiftData.baseline.lower_bound * 100
                                ).toFixed(2),
                            }),
                        ),
                        backgroundColor: Color(colors.yellow).alpha(0.2).string(),
                        fill: '-1', // fills the area between this line and the previous dataset
                        borderColor: 'transparent',
                    },
                ],
            };
        }
        return {
            datasets: [
                {
                    label: 'Revenue Difference',
                    data: _.map(
                        cumulativeDailyAverageRevenueDiffData.timestamp_data,
                        (val, key) => ({
                            x: key,
                            y: val.diff,
                        }),
                    ),
                    backgroundColor: primaryColors.pink,
                    borderColor: primaryColors.pink,
                    maxBarThickness: 30,
                    yAxisID: 'y',
                },
                {
                    label: 'Upper Bound',
                    data: _.map(
                        cumulativeDailyAverageRevenueDiffData.timestamp_data,
                        (val, key) => ({
                            x: key,
                            y: val.upper_bound,
                        }),
                    ),
                    backgroundColor: Color(primaryColors.pink).alpha(0.2).string(),
                    fill: '+1', // fills the area between this line and the next dataset
                    borderColor: 'transparent',
                },
                {
                    label: 'Lower Bound',
                    data: _.map(
                        cumulativeDailyAverageRevenueDiffData.timestamp_data,
                        (val, key) => ({
                            x: key,
                            y: val.lower_bound,
                        }),
                    ),
                    backgroundColor: Color(primaryColors.pink).alpha(0.2).string(),
                    fill: '-1', // fills the area between this line and the previous dataset
                    borderColor: 'transparent',
                },
                {
                    label: 'Baseline Upper Bound',
                    data: _.map(
                        cumulativeDailyAverageRevenueDiffData.timestamp_data,
                        (val, key) => ({
                            x: key,
                            y: cumulativeDailyAverageRevenueDiffData.baseline.upper_bound,
                        }),
                    ),
                    backgroundColor: Color(colors.yellow).alpha(0.2).string(),
                    fill: '+1', // fills the area between this line and the previous dataset
                    borderColor: 'transparent',
                },
                {
                    label: 'Baseline Lower Bound',
                    data: _.map(
                        cumulativeDailyAverageRevenueDiffData.timestamp_data,
                        (val, key) => ({
                            x: key,
                            y: cumulativeDailyAverageRevenueDiffData.baseline.lower_bound,
                        }),
                    ),
                    backgroundColor: Color(colors.yellow).alpha(0.2).string(),
                    fill: '-1', // fills the area between this line and the previous dataset
                    borderColor: 'transparent',
                },
            ],
        };
    }, [isLift, cumulativeDailyAverageRevenueDiffData, cumulativeDailyAverageRevenueLiftData]);

    const annotations = getCumulativeDailyAverageRevenueAnnotations(treatmentType, dateRange);
    const yDashedBaselineValue = isLift
        ? (cumulativeDailyAverageRevenueLiftData.baseline.mean * 100).toFixed(2)
        : cumulativeDailyAverageRevenueDiffData.baseline.mean;

    return (
        <div className="flex h-96">
            <LineChart
                className="grow"
                data={chartData}
                yAxis={{
                    title: {
                        text: isLift ? 'Lift' : 'Revenue Difference',
                        display: true,
                    },
                }}
                tooltip={{
                    callbacks: {
                        label(tooltipItem) {
                            if (tooltipItem.dataset.label === 'Lift') {
                                return `${tooltipItem.dataset.label} : ${tooltipItem.formattedValue}%`;
                            }
                            return `${tooltipItem.dataset.label} : ${tooltipItem.formattedValue}`;
                        },
                    },
                }}
                annotation={{
                    annotations: {
                        ...annotations,
                        yDashedBaseline: {
                            type: 'line',
                            scaleID: 'y',
                            value: yDashedBaselineValue,
                            borderColor: '#515674',
                            borderWidth: 1,
                            borderDash: [5, 5],
                        },
                    },
                }}
            />
        </div>
    );
};
