import React from 'react';
import _ from 'lodash';
import { formatNumber } from '@/utils/numberFormatHelpers';
import { primaryColors } from '@/constants/colors';
import Color from 'color';
import LineChart from '../../utils/charts/lineChart';
import { DailyGrossRevenueDataType } from './useDailyGrossRevenue';
import { getAnnotations } from './utils';

const tooltip = {
    callbacks: {
        label: (context: any) =>
            `${context?.dataset?.label}: ${formatNumber(context.parsed.y, '0.0a')}`,
    },
};

interface DailyGrossRevenueProps {
    dailyGrossRevenueData: DailyGrossRevenueDataType;
    treatmentType: string;
    dateRange: [string, string];
}

export const DailyGrossRevenue: React.FC<DailyGrossRevenueProps> = ({
    dailyGrossRevenueData,
    treatmentType,
    dateRange,
}) => {
    const chartData = {
        datasets: [
            {
                label: 'Treatment',
                tension: 0,
                backgroundColor: primaryColors.pink,
                borderColor: primaryColors.pink,
                data: _.map(dailyGrossRevenueData?.treatment, (val, key) => ({
                    x: key,
                    y: val.avg_daily_gross_revenue,
                })),
            },
            {
                label: 'Treatment Upper Bound',
                tension: 0,
                data: _.map(dailyGrossRevenueData?.treatment, (val, key) => ({
                    x: key,
                    y: val.upper_bound,
                })),
                backgroundColor: Color(primaryColors.pink).alpha(0.2).string(),
                fill: '+1', // fills the area between this line and the next dataset
                borderColor: 'transparent',
            },
            {
                label: 'Treatment Lower Bound',
                tension: 0,
                data: _.map(dailyGrossRevenueData?.treatment, (val, key) => ({
                    x: key,
                    y: val.lower_bound,
                })),
                backgroundColor: Color(primaryColors.pink).alpha(0.2).string(),
                fill: '-1', // fills the area between this line and the previous dataset
                borderColor: 'transparent',
            },
            {
                label: 'Control',
                tension: 0,
                backgroundColor: primaryColors.purple,
                borderColor: primaryColors.purple,
                data: _.map(dailyGrossRevenueData?.control, (val, key) => ({
                    x: key,
                    y: val.avg_daily_gross_revenue,
                })),
            },
            {
                label: 'Control Upper Bound',
                tension: 0,
                data: _.map(dailyGrossRevenueData?.control, (val, key) => ({
                    x: key,
                    y: val.upper_bound,
                })),
                backgroundColor: Color(primaryColors.purple).alpha(0.2).string(),
                fill: '+1', // fills the area between this line and the next dataset
                borderColor: 'transparent',
            },
            {
                label: 'Control Lower Bound',
                tension: 0,
                data: _.map(dailyGrossRevenueData?.control, (val, key) => ({
                    x: key,
                    y: val.lower_bound,
                })),
                backgroundColor: Color(primaryColors.purple).alpha(0.2).string(),
                fill: '-1', // fills the area between this line and the previous dataset
                borderColor: 'transparent',
            },
        ],
    };

    const xAxis = {
        type: 'time',
        time: {
            unit: 'day',
            displayFormats: {
                day: 'YYYY-MM-DD',
            },
        },
    } as const;

    const annotations = getAnnotations(treatmentType, dateRange);

    return (
        <div className="flex">
            <LineChart
                className="grow"
                data={chartData}
                tooltip={tooltip}
                annotation={{
                    annotations,
                }}
                xAxis={xAxis}
            />
        </div>
    );
};
