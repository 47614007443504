import { useMutation } from '@tanstack/react-query';
import { useAppContext } from '../../../contexts/appContext';
import { IRIS_URL } from '../../../buildConfig/processEnv';

type ExperimentData = {
    name: string;
    type: string;
    allocation_method: string;
    split_column: string;
    split_time_period: string;
    groups: {
        group_name: 'string';
        allocation_percentage: number;
        min_value: number;
        max_value: number;
        num_values: number;
        is_control: boolean;
    }[];
};

const useCreateInsomniaExperiment = () => {
    const { user } = useAppContext();
    const postRequest = async (data: ExperimentData) => {
        try {
            const requestOptions: RequestInit = {
                method: 'POST',
                headers: {
                    accept: 'application/json',
                    authorization: `Bearer ${user.siberiaToken}`,
                },
                body: JSON.stringify(data),
            };

            const url = `${IRIS_URL}/spaceport/api/optimizer/namespace/US/create_experiment`;

            const response = await fetch(url, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const body = await response.json();
            return body;
        } catch (error) {
            throw new Error('An error occurred while making the post request');
        }
    };

    return useMutation({ mutationKey: ['useCreateExperiment'], mutationFn: postRequest });
};

export default useCreateInsomniaExperiment;
