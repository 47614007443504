import React, { FC } from 'react';
import styled from 'styled-components';

const ColorBarContainer = styled.div<{ barHeight: number }>`
    display: flex;
    height: ${props => props.barHeight}px;
`;

const Bar = styled.div<{ colorPalette: string[] }>`
    width: 20px;
    height: 100%;
    background: linear-gradient(to top, ${props => props.colorPalette.join(', ')});
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-left: 10px;
`;

const Label = styled.div`
    text-align: left;
    font-size: 12px;
`;

interface ColorBarProps {
    className?: string;
    formatColorBarValue?: (value: number) => string;
    barHeight: number;
    colorPalette: string[];
    minValue: number;
    maxValue: number;
}

const ColorBarComponent: FC<ColorBarProps> = ({
    className,
    formatColorBarValue,
    colorPalette,
    minValue,
    maxValue,
    barHeight,
}) => (
    <ColorBarContainer className={className} barHeight={barHeight}>
        <Bar colorPalette={colorPalette} />
        <LabelContainer>
            <Label>{formatColorBarValue ? formatColorBarValue(maxValue) : maxValue}</Label>
            <Label>{formatColorBarValue ? formatColorBarValue(minValue) : minValue}</Label>
        </LabelContainer>
    </ColorBarContainer>
);

export default ColorBarComponent;
