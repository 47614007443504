import { BareCard, Spinner } from 'andromeda';
import React, { useMemo, useState } from 'react';
import DatePicker from 'andromeda/DatePicker';
import moment from 'moment';
import { DailyGrossRevenue } from './DailyGrossRevenue';
import { StyledDropdown } from './StyledDropdown';
import {
    CHART_TYPE_OPTIONS,
    ChartType,
    DATASET_VOLUME_OPTIONS,
    DatasetVolume,
    TREATMENT_TYPE_OPTIONS,
} from './types';
import { CumulativeDailyAverageRevenue } from './CumulativeDailyAverageRevenue';
import { getChartTitle } from './utils';
import { useDailyGrossRevenue } from './useDailyGrossRevenue';

export const Charts = () => {
    const [datasetVolume, setDatasetVolume] = useState<DatasetVolume>('all');
    const [treatmentType, setTreatmentType] = useState('added_treatment');
    const [dateRange, setDateRange] = useState<[string, string]>([
        moment('02-27-2024').toISOString(),
        moment().toISOString(),
    ]);

    const [chartType, setChartType] = useState<ChartType>('daily_average_revenue');

    const { data: dailyGrossRevenueData, isFetching } = useDailyGrossRevenue({
        volume: datasetVolume,
        treatmentType,
        startDate: dateRange[0],
        endDate: dateRange[1],
    });

    const selectedChart = useMemo(() => {
        if (chartType === 'daily_average_revenue') {
            return (
                <DailyGrossRevenue
                    dailyGrossRevenueData={dailyGrossRevenueData?.daily}
                    treatmentType={treatmentType}
                    dateRange={dateRange}
                />
            );
        }
        if (
            chartType === 'cumulative_daily_average_revenue_difference' ||
            chartType === 'cumulative_daily_average_revenue_lift'
        ) {
            return (
                <CumulativeDailyAverageRevenue
                    chartType={chartType}
                    cumulativeDailyAverageRevenueDiffData={dailyGrossRevenueData?.cumulative_diff}
                    cumulativeDailyAverageRevenueLiftData={dailyGrossRevenueData?.cumulative_lift}
                    treatmentType={treatmentType}
                    dateRange={dateRange}
                />
            );
        }
        return null;
    }, [chartType, dailyGrossRevenueData, treatmentType, dateRange]);

    const chartTitle = getChartTitle(chartType);
    return (
        <BareCard className="flex h-full flex-col gap-2 rounded">
            <div className="flex justify-end">
                <StyledDropdown
                    label=""
                    options={CHART_TYPE_OPTIONS}
                    value={chartType}
                    onChange={val => setChartType(val)}
                />
            </div>
            <div className="pl-[22px] text-sm text-gray-500">{chartTitle}</div>

            {isFetching && <Spinner className="grow" />}
            {!isFetching && selectedChart}
            <div className="flex flex-wrap items-center gap-4">
                <StyledDropdown
                    label="Treatment type"
                    options={TREATMENT_TYPE_OPTIONS}
                    value={treatmentType}
                    onChange={v => setTreatmentType(v)}
                />
                <StyledDropdown
                    label="Store volume context"
                    options={DATASET_VOLUME_OPTIONS}
                    value={datasetVolume}
                    onChange={v => setDatasetVolume(v)}
                />
                <div className="flex items-center gap-2">
                    <span>Date range</span>
                    <DatePicker
                        minDate={dateRange[0]}
                        maxDate={dateRange[1]}
                        handleChange={(...range) => setDateRange(range as [string, string])}
                    />
                </div>
            </div>
        </BareCard>
    );
};
