import { Dropdown, DropdownOption } from 'andromeda';
import React from 'react';

interface StyledDropdownProps<T> {
    options: DropdownOption<T>[];
    value: T;
    onChange: (value: T) => void;
    label: string;
}

export const StyledDropdown = <T extends string>({
    label,
    onChange,
    options,
    value,
}: StyledDropdownProps<T>) => (
    <div className="flex items-center gap-2">
        <span>{label}</span>
        <div className="rounded border border-primary-6 bg-primary-3 p-2">
            <Dropdown options={options} value={value} onChange={onChange} />
        </div>
    </div>
);
