import useApiQuery from '@/hooks/useApiQuery';
import { IRIS_URL } from 'buildConfig/processEnv';

export interface HeatmapResponse {
    context_groups: Record<string, Record<string, number>>;
}

export const useHeatmapData = () =>
    useApiQuery<HeatmapResponse>({
        url: `${IRIS_URL}/spaceport/api/optimizer/namespace/US/get_heatmap`,
        method: 'POST',
        body: {},
    });
